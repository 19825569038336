@use 'sass:map';
@use './../../../../styles/variables/colors.scss';
@use './../../../../styles/base/spaces.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  width: map.get(spaces.$spaces, '7');
  height: auto;
}
.iconAquamarine {
  -webkit-filter: drop-shadow(0 0 1px map.get(colors.$colors, 'aquamarineWhite'))
    drop-shadow(0 0 2px map.get(colors.$colors, 'aquamarine'))
    drop-shadow(0 0 4px map.get(colors.$colors, 'aquamarine'))
    drop-shadow(0 0 8px map.get(colors.$colors, 'aquamarine'));
  filter: drop-shadow(0 0 1px map.get(colors.$colors, 'aquamarineWhite'))
    drop-shadow(0 0 2px map.get(colors.$colors, 'aquamarine'))
    drop-shadow(0 0 4px map.get(colors.$colors, 'aquamarine'))
    drop-shadow(0 0 8px map.get(colors.$colors, 'aquamarine'));
}
.iconPink {
  -webkit-filter: drop-shadow(0 0 1px map.get(colors.$colors, 'primaryWhitest'))
    drop-shadow(0 0 2px map.get(colors.$colors, 'primary'))
    drop-shadow(0 0 4px map.get(colors.$colors, 'primary'))
    drop-shadow(0 0 8px map.get(colors.$colors, 'primary'));
  filter: drop-shadow(0 0 1px map.get(colors.$colors, 'primaryWhitest'))
    drop-shadow(0 0 2px map.get(colors.$colors, 'primary'))
    drop-shadow(0 0 4px map.get(colors.$colors, 'primary'))
    drop-shadow(0 0 8px map.get(colors.$colors, 'primary'));
}
.iconBlinkingFast {
  -webkit-animation: blinking 2.5s linear infinite;
  animation: blinking 2.5s linear infinite;
}
.iconBlinkingSlow {
  -webkit-animation: blinking 4s linear infinite;
  animation: blinking 4s linear infinite;
}

@-webkit-keyframes blinking {
  0%,
  19.999%,
  22%,
  62.999%,
  64%,
  64.999%,
  70%,
  100% {
    opacity: 0.99;
  }
  20%,
  21.999%,
  63%,
  63.999%,
  65%,
  69.999% {
    opacity: 0.4;
  }
}

@keyframes blinking {
  0%,
  19.999%,
  22%,
  62.999%,
  64%,
  64.999%,
  70%,
  100% {
    opacity: 0.99;
  }
  20%,
  21.999%,
  63%,
  63.999%,
  65%,
  69.999% {
    opacity: 0.4;
  }
}
