@use 'sass:map';
@use './../../../../styles/base/spaces.scss';
@use './../../../../styles/base/breakpoints.scss';
@use './../../../../styles/variables/colors.scss';
@import './../../../../styles/base/mediaQueries.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-left: 10%;

  @include media-query-max(breakpoints.$screenLarge) {
    width: 90%;
    margin-left: 5%;
  }
  @include media-query-max(breakpoints.$screenMedium) {
    width: 95%;
    margin-left: 2.5%;
  }
  @include media-query-max(breakpoints.$screenSmall) {
    width: 100%;
    margin-left: 0%;
  }
}
.table {
  width: 100%;
  border-spacing: 0;
  border-radius: map.get(spaces.$spaces, '2');
  border: 1px map.get(colors.$colors, 'white') solid;
}
.tableHeader,
.tableData {
  padding: map.get(spaces.$spaces, '3') map.get(spaces.$spaces, '3');
  text-align: center;
  width: 50%;
}
.text {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  vertical-align: bottom;
}
.tableHeader {
  font-family: 'Oswald-Regular', sans-serif;
  border-bottom: 1px map.get(colors.$colors, 'white') solid;
}
.glowPinkHeader {
  border-radius: 6px 0px 0px 0px;
  box-shadow: 0 0 2px map.get(colors.$colors, 'primaryWhitest'),
    inset 0 0 2px map.get(colors.$colors, 'primaryWhitest'),
    0 0 7px map.get(colors.$colors, 'primary'), inset 0 0 7px map.get(colors.$colors, 'primary'),
    0 0 21px map.get(colors.$colors, 'primary'), inset 0 0 21px map.get(colors.$colors, 'primary');
}
.glowAquamarineHeader {
  border-radius: 0px 6px 0px 0px;
  box-shadow: 0 0 2px map.get(colors.$colors, 'aquamarineWhite'),
    inset 0 0 2px map.get(colors.$colors, 'aquamarineWhite'),
    0 0 7px map.get(colors.$colors, 'aquamarine'),
    inset 0 0 7px map.get(colors.$colors, 'aquamarine'),
    0 0 21px map.get(colors.$colors, 'aquamarine'),
    inset 0 0 21px map.get(colors.$colors, 'aquamarine');
}
.tableBody {
  border-top: 1px map.get(colors.$colors, 'white') solid;
  border-radius: 0px 0px 6px 6px;
}
.shadow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: map.get(colors.$colors, 'black');
  border-radius: map.get(spaces.$spaces, '2');
}
.shadow:before,
.shadow:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    map.get(colors.$colors, 'primary'),
    map.get(colors.$colors, 'primary'),
    map.get(colors.$colors, 'primary'),
    map.get(colors.$colors, 'primary'),
    map.get(colors.$colors, 'primary'),
    map.get(colors.$colors, 'primary'),
    map.get(colors.$colors, 'aquamarine'),
    map.get(colors.$colors, 'aquamarine'),
    map.get(colors.$colors, 'aquamarine'),
    map.get(colors.$colors, 'aquamarine'),
    map.get(colors.$colors, 'aquamarine'),
    map.get(colors.$colors, 'aquamarine')
  );
  background-size: 100%;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: map.get(spaces.$spaces, '2');
}

.shadow:after {
  -webkit-filter: blur(map.get(spaces.$spaces, '4'));
  filter: blur(map.get(spaces.$spaces, '4'));
}

.eliminated {
  text-decoration: line-through;
  color: #a3a3a3;
}
