@use 'sass:map';
@use './../../../../styles/variables/colors.scss';
@use './../../../../styles/base/spaces.scss';

.word {
  font-family: 'Neon', sans-serif;
  text-align: center;
}
.wordPink {
  color: map.get(colors.$colors, 'primaryWhitest');
  text-shadow: 0 0 4px map.get(colors.$colors, 'primaryWhitest'),
    0 0 11px map.get(colors.$colors, 'primary'), 0 0 19px map.get(colors.$colors, 'primary'),
    0 0 40px map.get(colors.$colors, 'primary'), 0 0 80px map.get(colors.$colors, 'primary'),
    0 0 90px map.get(colors.$colors, 'primary'), 0 0 100px map.get(colors.$colors, 'primary'),
    0 0 150px map.get(colors.$colors, 'primary');
}
.wordAquamarine {
  color: map.get(colors.$colors, 'aquamarineWhite');
  text-shadow: 0 0 10px map.get(colors.$colors, 'aquamarineWhite'),
    0 0 11px map.get(colors.$colors, 'aquamarine'), 0 0 19px map.get(colors.$colors, 'aquamarine'),
    0 0 40px map.get(colors.$colors, 'aquamarine'), 0 0 80px map.get(colors.$colors, 'aquamarine'),
    0 0 90px map.get(colors.$colors, 'aquamarine'), 0 0 100px map.get(colors.$colors, 'aquamarine'),
    0 0 150px map.get(colors.$colors, 'aquamarine');
}
.letter {
  font-size: inherit;
  font-family: 'Neon', sans-serif;
}
.letterBlinkFast {
  -webkit-animation: blinking 1s linear infinite;
  animation: blinking 1s linear infinite;
}
.letterBlinkSlow {
  -webkit-animation: blinking 3s linear infinite;
  animation: blinking 3s linear infinite;
}

@-webkit-keyframes blinking {
  0%,
  19.999%,
  22%,
  62.999%,
  64%,
  64.999%,
  70%,
  100% {
    opacity: 0.99;
  }
  20%,
  21.999%,
  63%,
  63.999%,
  65%,
  69.999% {
    opacity: 0.4;
  }
}

@keyframes blinking {
  0%,
  19.999%,
  22%,
  62.999%,
  64%,
  64.999%,
  70%,
  100% {
    opacity: 0.99;
  }
  20%,
  21.999%,
  63%,
  63.999%,
  65%,
  69.999% {
    opacity: 0.4;
  }
}
