@mixin media-query-max($size) {
  @media only screen and (max-width: $size) {
    @content;
  }
}
@mixin media-query-min($size) {
  @media only screen and (min-width: $size) {
    @content;
  }
}
