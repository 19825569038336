@use 'sass:map';
@use './../../../styles/base/spaces.scss';
@use './../../../styles/base/breakpoints.scss';
@use './../../../styles/variables/colors.scss';
@import './../../../styles/base/mediaQueries.scss';

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
  scroll-margin: 88px;
  scroll-snap-align: start;
  scroll-snap-stop: always;

  @include media-query-max(breakpoints.$screenSmall) {
    flex-direction: column;
  }
  @include media-query-max(breakpoints.$screenSmall) {
    width: 90%;
    margin-left: 5%;
  }
}
.infosContainer {
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 40%;

  @include media-query-max(breakpoints.$screenLarge) {
    width: 55%;
  }
  @include media-query-max(breakpoints.$screenSmall) {
    width: 100%;
    gap: 16px;
  }
}
.namePriceContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.image,
.carouselContainer {
  width: 40%;
  @include media-query-max(breakpoints.$screenSmall) {
    width: 100%;
  }
}
.name {
  text-wrap: wrap;
  text-wrap: pretty;
}
.price {
  font-weight: 700;
}
.linkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.carousel {
  width: 100%;
  height: 100%;
}
.carouselImage {
  width: 100%;
  height: auto;
}
