@use 'sass:map';
@use './../../../styles/base/spaces.scss';
@use './../../../styles/base/breakpoints.scss';
@import './../../../styles/base/mediaQueries.scss';

.container {
  width: 70%;
  margin-left: 15%;
  display: flex;
  flex-direction: column;
  gap: map.get(spaces.$spaces, '13');

  @include media-query-min(breakpoints.$screenDoubleExtraLarge) {
    width: 60%;
    margin-left: 20%;
  }
  @include media-query-max(breakpoints.$screenLarge) {
    width: 80%;
    margin-left: 10%;
  }
  @include media-query-max(breakpoints.$screenMedium) {
    width: 85%;
    margin-left: 7.5%;
  }
  @include media-query-max(breakpoints.$screenSmall) {
    width: 90%;
    margin-left: 5%;
  }
}
