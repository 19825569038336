@use 'sass:map';
@use './../../../../../styles/base/spaces.scss';
@use './../../../../../styles/base/breakpoints.scss';
@import './../../../../../styles/base/mediaQueries.scss';

.container {
  scroll-margin: 207px;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  margin-top: map.get(spaces.$spaces, '8');
  padding-bottom: map.get(spaces.$spaces, '11');

  @include media-query-min(breakpoints.$screenDoubleExtraLarge) {
    margin-top: map.get(spaces.$spaces, '9');
    scroll-margin: 243px;
  }
}
.title {
  letter-spacing: map.get(spaces.$spaces, '3');
  margin-bottom: map.get(spaces.$spaces, '8');
  font-size: 8rem;
  text-indent: map.get(spaces.$spaces, '3');

  @include media-query-max(breakpoints.$screenLarge) {
    font-size: 7rem;
  }
  @include media-query-max(breakpoints.$screenMedium) {
    font-size: 6rem;
  }
  @include media-query-max(breakpoints.$screenSmall) {
    font-size: 19svw;
    // font-size: 4.5rem;
  }
}
.countdown {
  margin-bottom: map.get(spaces.$spaces, '7');
}
.registerButton {
  margin-bottom: map.get(spaces.$spaces, '7');
}
