@use 'sass:map';
@use './../../../../../styles/base/spaces.scss';
@use './../../../../../styles/base/breakpoints.scss';
@use './../../../../../styles/variables/colors.scss';
@import './../../../../../styles/base/mediaQueries.scss';

.container {
  scroll-margin: 88px;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  width: 80%;
  margin-left: 10%;
  padding-bottom: map.get(spaces.$spaces, '11');

  @include media-query-min(breakpoints.$screenDoubleExtraLarge) {
    scroll-margin: 144px;
  }

  @include media-query-max(breakpoints.$screenLarge) {
    width: 90%;
    margin-left: 5%;
  }
  @include media-query-max(breakpoints.$screenMedium) {
    width: 90%;
    margin-left: 5%;
  }
  @include media-query-max(breakpoints.$screenSmall) {
    width: 95%;
    margin-left: 2.5%;
  }
}
.centerText {
  text-align: center;
}
.subTitle {
  margin-bottom: map.get(spaces.$spaces, '4');
}
.paragrapheContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: map.get(spaces.$spaces, '5');
}
.paragraphe {
  text-align: justify;
  line-height: 1.75rem;
}
.bold {
  font-family: 'Oswald-Regular', sans-serif;
}
.iconsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 0px;

  @include media-query-max(breakpoints.$screenMedium) {
    flex-direction: column;
    gap: map.get(spaces.$spaces, '3');
  }
}
.iconContainer {
  display: flex;
  flex-direction: column;
  gap: map.get(spaces.$spaces, '2');
  &:hover .iconText {
    border-bottom: 1px map.get(colors.$colors, 'primary') solid;
    @include media-query-max(breakpoints.$screenMedium) {
      border-bottom: 1px transparent solid;
    }
  }
}
.iconText {
  transition: border-bottom 200ms ease;
  border-bottom: 1px transparent solid;
  padding-bottom: 4px;
}
.iconTwitterMarginBottom {
  margin-bottom: map.get(spaces.$spaces, '3');
}
