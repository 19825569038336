@use 'sass:map';
@use './../../../styles/base/spaces.scss';
@use './../../../styles/base/breakpoints.scss';
@use './../../../styles/variables/colors.scss';
@import './../../../styles/base/mediaQueries.scss';

.container {
  border: 1px map.get(colors.$colors, 'aquamarineWhite') solid;
  border-radius: map.get(spaces.$spaces, '2');
  padding: map.get(spaces.$spaces, '0');
  // -webkit-animation: glowAquamarine 3s ease alternate infinite;
  // animation: glowAquamarine 3s ease alternate infinite;
  width: 80%;
  margin-left: 10%;
  position: relative;

  @include media-query-max(breakpoints.$screenLarge) {
    width: 85%;
    margin-left: 7.5%;
  }
  @include media-query-max(breakpoints.$screenMedium) {
    width: 90%;
    margin-left: 5%;
  }
  @include media-query-max(breakpoints.$screenSmall) {
    width: 100%;
    margin-left: 0%;
  }
}
.container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-radius: map.get(spaces.$spaces, '2');
  width: 100%;
  height: 100%;
  -webkit-animation: glowAquamarine 3s ease alternate infinite;
  animation: glowAquamarine 3s ease alternate infinite;
}
.carousel {
  border-radius: map.get(spaces.$spaces, '2');
}
.carouselSlideContainer {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
.carouselImage {
  display: block;
  width: 100%;
  height: 25rem;
  -o-object-fit: cover;
  object-fit: cover;
}
.carouselTextContainer {
  width: 100%;
  height: 30%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 41, 35, 0) 0%, rgba(0, 41, 35, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.carouselText {
  font-size: 2rem;
  font-weight: 700;
  font-family: 'Oswald-Regular';
}

@-webkit-keyframes glowAquamarine {
  100% {
    box-shadow: 0 0 3px map.get(colors.$colors, 'aquamarineWhite'),
      0 0 8px map.get(colors.$colors, 'aquamarine'), 0 0 14px map.get(colors.$colors, 'aquamarine'),
      0 0 22px map.get(colors.$colors, 'aquamarine'), 0 0 28px map.get(colors.$colors, 'aquamarine');
  }
  0% {
    box-shadow: 0 0 1px map.get(colors.$colors, 'aquamarineWhite'),
      0 0 4px map.get(colors.$colors, 'aquamarine'), 0 0 9px map.get(colors.$colors, 'aquamarine'),
      0 0 16px map.get(colors.$colors, 'aquamarine'), 0 0 19px map.get(colors.$colors, 'aquamarine');
  }
}

@keyframes glowAquamarine {
  100% {
    box-shadow: 0 0 3px map.get(colors.$colors, 'aquamarineWhite'),
      0 0 8px map.get(colors.$colors, 'aquamarine'), 0 0 14px map.get(colors.$colors, 'aquamarine'),
      0 0 22px map.get(colors.$colors, 'aquamarine'), 0 0 28px map.get(colors.$colors, 'aquamarine');
  }
  0% {
    box-shadow: 0 0 1px map.get(colors.$colors, 'aquamarineWhite'),
      0 0 4px map.get(colors.$colors, 'aquamarine'), 0 0 9px map.get(colors.$colors, 'aquamarine'),
      0 0 16px map.get(colors.$colors, 'aquamarine'), 0 0 19px map.get(colors.$colors, 'aquamarine');
  }
}
