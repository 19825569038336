@use 'sass:map';
@use './../../../styles/base/spaces.scss';
@use './../../../styles/base/breakpoints.scss';
@use './../../../styles/variables/colors.scss';
@import './../../../styles/base/mediaQueries.scss';

.container {
  border-top: 1px map.get(colors.$colors, 'aquamarine') solid;
  margin-top: map.get(spaces.$spaces, '8');
  padding-top: map.get(spaces.$spaces, '7');
  padding-bottom: map.get(spaces.$spaces, '8');
  scroll-margin: map.get(spaces.$spaces, '0');
  scroll-snap-align: end;
  scroll-snap-stop: always;
  position: relative;
}
.container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: glowAquamarine 2s ease alternate infinite;
  animation: glowAquamarine 2s ease alternate infinite;
  pointer-events: none;
}
.subContainer {
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: map.get(spaces.$spaces, '0');

  @include media-query-max(breakpoints.$screenLarge) {
    width: 90%;
    margin-left: 5%;
  }
  @include media-query-max(breakpoints.$screenMedium) {
    width: 90%;
    margin-left: 5%;
    flex-direction: column;
    gap: map.get(spaces.$spaces, '4');
  }
  @include media-query-max(breakpoints.$screenSmall) {
    width: 95%;
    margin-left: 2.5%;
    flex-direction: column;
  }
}
.logoContainer,
.brolanContainer,
.otherContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 1 0;
  width: 0;

  @include media-query-max(breakpoints.$screenMedium) {
    flex: auto;
    width: 100%;
  }
  @include media-query-max(breakpoints.$screenSmall) {
    width: 100%;
  }
}
.otherContainer {
  gap: map.get(spaces.$spaces, '2');
}
.link {
  transition: border-bottom 200ms ease;
  border-bottom: 1px transparent solid;

  &:hover {
    border-bottom: 1px map.get(colors.$colors, 'primary') solid;
    @include media-query-max(breakpoints.$screenMedium) {
      border-bottom: 1px transparent solid;
    }
  }
}
.logo,
.brolan,
.link {
  padding: map.get(spaces.$spaces, '2');
}
.logo {
  width: map.get(spaces.$spaces, '7');
  height: map.get(spaces.$spaces, '7');

  @include media-query-max(breakpoints.$screenSmall) {
    width: map.get(spaces.$spaces, '6');
    height: map.get(spaces.$spaces, '6');
  }
}
.paypalLogo {
  width: 1.15rem;
  height: auto;
}

@-webkit-keyframes glowAquamarine {
  100% {
    box-shadow: 0 0 2px map.get(colors.$colors, 'aquamarineWhite'),
      inset 0 3px 3px -3px map.get(colors.$colors, 'aquamarineWhite'),
      0 0 7px map.get(colors.$colors, 'aquamarine'),
      inset 0 9px 9px -9px map.get(colors.$colors, 'aquamarine'),
      0 0 21px map.get(colors.$colors, 'aquamarine'),
      inset 0 24px 24px -24px map.get(colors.$colors, 'aquamarine');
  }
  0% {
    box-shadow: 0 0 1px map.get(colors.$colors, 'aquamarineWhite'),
      inset 0 2px 2px -2px map.get(colors.$colors, 'aquamarineWhite'),
      0 0 7px map.get(colors.$colors, 'aquamarine'),
      inset 0 9px 9px -9px map.get(colors.$colors, 'aquamarine'),
      0 0 13px map.get(colors.$colors, 'aquamarine'),
      inset 0 15px 15px -15px map.get(colors.$colors, 'aquamarine');
  }
}

@keyframes glowAquamarine {
  100% {
    box-shadow: 0 0 2px map.get(colors.$colors, 'aquamarineWhite'),
      inset 0 3px 3px -3px map.get(colors.$colors, 'aquamarineWhite'),
      0 0 7px map.get(colors.$colors, 'aquamarine'),
      inset 0 9px 9px -9px map.get(colors.$colors, 'aquamarine'),
      0 0 21px map.get(colors.$colors, 'aquamarine'),
      inset 0 24px 24px -24px map.get(colors.$colors, 'aquamarine');
  }
  0% {
    box-shadow: 0 0 1px map.get(colors.$colors, 'aquamarineWhite'),
      inset 0 2px 2px -2px map.get(colors.$colors, 'aquamarineWhite'),
      0 0 7px map.get(colors.$colors, 'aquamarine'),
      inset 0 9px 9px -9px map.get(colors.$colors, 'aquamarine'),
      0 0 13px map.get(colors.$colors, 'aquamarine'),
      inset 0 15px 15px -15px map.get(colors.$colors, 'aquamarine');
  }
}
