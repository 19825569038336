@use 'sass:map';
@use './styles/variables/colors.scss';

@font-face {
  font-family: 'Neon';
  src: local('Neon'), url('./fonts/Neon/Neon.ttf') format('truetype');
}
@font-face {
  font-family: 'Oswald';
  src: local('Oswald-Light'), url('./fonts/Oswald/Oswald-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Oswald-Regular';
  src: local('Oswald-Regular'), url('./fonts/Oswald/Oswald-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Oswald-Bold';
  src: local('Oswald-Bold'), url('./fonts/Oswald/Oswald-Bold.ttf') format('truetype');
}

span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
i,
ul,
td,
th,
img {
  color: map.get(colors.$colors, 'white');
  font-family: 'Oswald', sans-serif;
  font-size: 1.15rem;
}
html {
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
}
body {
  overflow-x: hidden;
  background-color: map.get(colors.$colors, 'black');
}

h1 {
  font-size: 2.44125rem;
  line-height: 2.925rem;
  font-weight: 700;
}
h2 {
  font-size: 1.953125rem;
  line-height: 2.34375rem;
  font-weight: 700;
}
h3 {
  font-size: 1.5625rem;
  line-height: 1.875rem;
  font-weight: 700;
}
h4 {
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: 700;
}
span.swiper-pagination-bullet {
  background-color: map.get(colors.$colors, 'lightGrey');
}
span.swiper-pagination-bullet-active {
  background-color: map.get(colors.$colors, 'aquamarine');
}
