@use 'sass:map';
@use './../../../styles/base/spaces.scss';
@use './../../../styles/base/breakpoints.scss';
@use './../../../styles/variables/colors.scss';
@import './../../../styles/base/mediaQueries.scss';

.container {
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  width: 100%;
  top: 0;
  left: 0;
  height: 80px;
  z-index: 999;
  background: map.get(colors.$colors, 'black');
  background: linear-gradient(0deg, rgba(1, 4, 0, 0) 0%, rgb(1, 4, 0) 80%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.subContainer {
  display: flex;
  width: 80%;
  margin-left: 10%;
  flex-direction: row;
  justify-content: space-between;

  @include media-query-max(breakpoints.$screenMedium) {
  }
  width: calc(80% + 12px);
  margin-left: calc(10% - 12px);
}

.logoContainer,
.burgerMenuContainer,
.linkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  height: map.get(spaces.$spaces, '6');
  width: map.get(spaces.$spaces, '6');
}
.burgerMenu {
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap-reverse;
}
.burgerMenuBigStroke {
  width: 100%;
  height: 1px;
  background-color: map.get(colors.$colors, 'white');
}
.burgerMenuLittleStroke {
  width: 66%;
  height: 1px;
  background-color: map.get(colors.$colors, 'white');
}

.linksContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: map.get(spaces.$spaces, '4');
  background-color: transparent;
  left: -120%;

  @include media-query-max(breakpoints.$screenMedium) {
    background-color: map.get(colors.$colors, 'black');
    flex-direction: column;
    margin-top: map.get(spaces.$spaces, '7');
    position: absolute;
    height: calc(100svh - 14.5px);
    width: 100svw;
    top: 7.5px;
    justify-content: flex-start;
    transition: left 250ms ease;
    padding-top: map.get(spaces.$spaces, '6');
  }
}

.showMobile {
  @include media-query-max(breakpoints.$screenMedium) {
    left: 0%;
  }
}

.link {
  padding-bottom: 0px;
  border-bottom: 1px transparent solid;
  transition: border-bottom 200ms ease, padding-bottom 200ms ease;
  @include media-query-max(breakpoints.$screenMedium) {
    text-align: center;
    font-size: 1.25rem;
  }
}

.linkContainer {
  padding: map.get(spaces.$spaces, '2');

  &:hover .link {
    padding-bottom: 4px;
    border-bottom: 1px map.get(colors.$colors, 'primary') solid;
    @include media-query-max(breakpoints.$screenMedium) {
      border-bottom: 1px transparent solid;
      padding-bottom: 0px;
    }
  }
}
.buttonMobile {
  @include media-query-max(breakpoints.$screenMedium) {
    width: 100%;
  }
}
